import React from "react";

const LinkedinIcon = () => {
  return (
    <div className="Linkedin-Icon py-3">
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.25 2.5475C0.25 1.93817 0.492058 1.35379 0.922923 0.922925C1.35379 0.49206 1.93817 0.250003 2.5475 0.250003H25.45C25.752 0.24951 26.0511 0.308582 26.3302 0.423836C26.6093 0.539091 26.8629 0.708265 27.0766 0.921673C27.2902 1.13508 27.4597 1.38853 27.5752 1.66752C27.6908 1.9465 27.7502 2.24553 27.75 2.5475V25.45C27.7503 25.752 27.6911 26.0512 27.5757 26.3303C27.4603 26.6094 27.291 26.863 27.0775 27.0766C26.864 27.2903 26.6105 27.4597 26.3314 27.5752C26.0524 27.6908 25.7533 27.7502 25.4513 27.75H2.5475C2.24568 27.75 1.94682 27.6905 1.668 27.575C1.38917 27.4595 1.13584 27.2901 0.92248 27.0766C0.709121 26.8632 0.539915 26.6097 0.424528 26.3309C0.309142 26.052 0.249836 25.7531 0.25 25.4513V2.5475ZM11.135 10.735H14.8588V12.605C15.3963 11.53 16.7713 10.5625 18.8375 10.5625C22.7988 10.5625 23.7375 12.7038 23.7375 16.6325V23.91H19.7288V17.5275C19.7288 15.29 19.1913 14.0275 17.8263 14.0275C15.9325 14.0275 15.145 15.3888 15.145 17.5275V23.91H11.135V10.735ZM4.26 23.7388H8.27V10.5625H4.26V23.7375V23.7388ZM8.84375 6.265C8.85131 6.60834 8.79022 6.94974 8.66405 7.26915C8.53788 7.58856 8.34919 7.87955 8.10904 8.12504C7.86889 8.37054 7.58212 8.56559 7.26557 8.69876C6.94902 8.83193 6.60905 8.90053 6.26563 8.90053C5.9222 8.90053 5.58224 8.83193 5.26568 8.69876C4.94913 8.56559 4.66236 8.37054 4.42221 8.12504C4.18206 7.87955 3.99337 7.58856 3.8672 7.26915C3.74104 6.94974 3.67994 6.60834 3.6875 6.265C3.70234 5.59107 3.98048 4.94974 4.46236 4.47836C4.94423 4.00698 5.59153 3.74303 6.26563 3.74303C6.93972 3.74303 7.58702 4.00698 8.06889 4.47836C8.55077 4.94974 8.82891 5.59107 8.84375 6.265Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default LinkedinIcon;
