import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
// Add Font Tahoma
import './styles/fonts/Tahoma Regular font.ttf'; 

// Routes Main?
import Routes from "./routes/Routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Routes></Routes>
  </React.StrictMode>
);
