import React from "react";
import { BrowserRouter, Route, Routes as RoutesDom } from "react-router-dom";

// Header
import Header from "./header/Header";

// Components Pages
import Home from "./pages/Home";
import PostOffer from "./pages/PostOffer";
import ExploreApplicant from "./pages/ExploreApplicant";
import ExploreProfiles from "./pages/ExploreProfiles";

const Routes = () => {
  return (
    <div className="Routes">
      <BrowserRouter>
        <div>
          <Header></Header>
          {/* In react-router-dom v6, "Switch" is replaced by routes "Routes" */}
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <RoutesDom>
            <Route path="/" element={<Home />} />
            <Route path="/new" element={<PostOffer />} />
            <Route path="/applicants" element={<ExploreApplicant />} />
            <Route path="/explore" element={<ExploreProfiles />} />
          </RoutesDom>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default Routes;
