import React from "react";
import BannerHome from '../../components/organisms/BannerHome';

const Home = () => {
  return (
      <div className="Home">
          <BannerHome></BannerHome>
      </div>
  );
};

export default Home;