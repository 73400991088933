import React from "react";

import HeaderButtons from "../../components/molecules/HeaderButtons";

const Header = () => {
  return (
    <div className="Header-Routes py-14 bg-blue">
      <HeaderButtons></HeaderButtons>
    </div>
  );
};

export default Header;
