import React from "react";

import ButtonIcon from "../atoms/buttons/ButtonIcon";
import FacebookIcon from "../atoms/icons/FacebookIcon";
import InstagramIcon from "../atoms/icons/InstagramIcon";
import LinkedinIcon from "../atoms/icons/LinkedinIcon";

const SocialButtons = () => {
  return (
    <div className="Social-Buttons flex flex-col bg-black p-10">
      <div className="grow">
        <ButtonIcon>
          <FacebookIcon></FacebookIcon>
        </ButtonIcon>
      </div>
      <div className="grow">
        <ButtonIcon>
          <LinkedinIcon></LinkedinIcon>
        </ButtonIcon>
      </div>
      <div className="grow">
        <ButtonIcon>
          <InstagramIcon></InstagramIcon>
        </ButtonIcon>
      </div>
    </div>
  );
};

export default SocialButtons;
