import React from 'react';
import SocialButtons from '../molecules/SocialButtons';
import HeadingText from '../atoms/HeadingText';
import BannerIcon from '../atoms/icons/BannerIcon';

const BannerHome = () => {
    return (
        <div className="Banner-Home flex flex-row items-center bg-blue">
            <SocialButtons></SocialButtons>
            <HeadingText>UNA FRASE MOTIVACIONAL</HeadingText>
            <BannerIcon></BannerIcon>
        </div>
    );
};

export default BannerHome;