import React from "react";

// Buttons
import ButtonHeader from "../atoms/buttons/ButtonHeader";

// Link. react-route-dom
import { Link } from "react-router-dom";

// Hook react router-dom
import { useLocation } from "react-router-dom";

const HeaderButtons = () => {
  const location = useLocation();

  const helperFunction = () => {
    if (location.pathname !== "/") {
      return (
        <Link to="/">
          <ButtonHeader value="Home"></ButtonHeader>
        </Link>
      );
    }
    return null;
  };

  return (
    <div className="Header-Buttons pl-12">
      {/* Se deberian corregir los value ? estan al reves? */}
      <Link to="/new">
        <ButtonHeader value="Publicar Oferta"></ButtonHeader>
      </Link>
      <Link to="/explore">
        <ButtonHeader value="Revisar Perfiles"></ButtonHeader>
      </Link>
      <Link to="/applicants">
        <ButtonHeader value="Buscar Candidatos"></ButtonHeader>
      </Link>
      {helperFunction()}
    </div>
  );
};

export default HeaderButtons;
